import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'Library',
  components: {
  },
  data: () => ({
    dialog: false,
    dialogDeleteConfirm: false,
    search: {
      criteria: ''
    },
    selectForDelete: { },
    upload: {
      name: '',
      category: '',
      checkboxSpyUp: false,
      checkboxSpyDown: false,
      checkboxFedEase: false,
      checkboxFedHike: false,
      file: null
    },
    categories: [
      { name: 'Company Memos', value: 'cat1' },
      { name: 'Learning Materials', value: 'cat2' }
    ],
    table: {
      headers: {
        cat1: [
          { text: 'Name', value: 'name', width: '40%', sortable: false },
          { text: 'SPY 10% up', value: 'spyUp', width: '10%' },
          { text: 'SPY 10% down', value: 'spyDown', width: '10%' },
          { text: 'FED ease', value: 'fedEase', width: '10%' },
          { text: 'FED hike', value: 'fedHike', width: '10%' },
          { text: 'Action', value: 'action', width: '20%', sortable: false }
        ],
        cat2: [
          { text: 'Name', value: 'name', width: '80%' },
          { text: 'Action', value: 'action', width: '20%' }
        ]
      },
      data: {
        cat1: [],
        cat2: []
      },
      pagination: {
        cat1: {
          number: 1,
          pages: 1
        },
        cat2: {
          number: 1,
          pages: 1
        }
      }
    },
    options: {
    },
    loading: false
  }),
  computed: {
    ...mapGetters(['hasPermission', 'userInfo']),
    isAdmin () {
      return this.hasPermission('LIBRARY_ADMIN')
    }
  },
  beforeMount () {
    this.filter()
  },
  watch: {
    options: {
      async handler () {
        await this.filterCategory('cat1')
      },
      deep: true
    }
  },
  methods: {
    async filter () {
      this.loading = true
      axios.get(`/api/v1/library/?criteria=${this.search.criteria || ''}`)
        .then(r => {
          this.table.data.cat1 = r.data.cat1.content
          this.table.data.cat2 = r.data.cat2.content
          this.table.pagination.cat1.number = r.data.cat1.pageable.pageNumber + 1
          this.table.pagination.cat1.pages = r.data.cat1.totalPages
          this.table.pagination.cat2.number = r.data.cat2.pageable.pageNumber + 1
          this.table.pagination.cat2.pages = r.data.cat2.totalPages
          this.loading = false
        })
        .catch(e => {
          this.table.data = {
            cat1: [],
            cat2: []
          }
          this.table.pagination.cat1.number = 1
          this.table.pagination.cat1.pages = 1
          this.table.pagination.cat2.number = 1
          this.table.pagination.cat2.pages = 1
          this.loading = false
        })
    },
    async uploadFile () {
      this.loading = true

      const formData = new FormData()
      formData.append('file', this.upload.file)
      formData.append('category', this.upload.category)
      formData.append('name', this.upload.name)
      formData.append('spyUp', this.upload.checkboxSpyUp)
      formData.append('spyDown', this.upload.checkboxSpyDown)
      formData.append('fedEase', this.upload.checkboxFedEase)
      formData.append('fedHike', this.upload.checkboxFedHike)

      axios.post('/api/v1/library/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(r => {
        if (r.status === 200) {
          this.upload = {
            name: '',
            category: '',
            file: null,
            checkboxSpyUp: false,
            checkboxSpyDown: false,
            checkboxFedEase: false,
            checkboxFedHike: false
          }
          this.filter()
        } else {
          this.dialog = true
        }
        this.loading = false
      }).catch(e => {
        this.loading = false
        this.dialog = true
      })
    },
    async filterCategory (cat) {
      this.loading = true
      let sort = ''
      for (let i = 0; i < this.options.sortBy.length; i++) {
        sort += '&sort=' + this.options.sortBy[i] + ',' + (this.options.sortDesc[i] ? 'DESC' : 'ASC')
      }
      axios.get(`/api/v1/library/category/${cat}?criteria=${this.search.criteria || ''}&page=${this.table.pagination[cat].number - 1}&size=15${sort}&sort=createdAt,DESC`)
        .then(r => {
          this.table.data[cat] = r.data.content
          this.table.pagination[cat].number = r.data.pageable.pageNumber + 1
          this.table.pagination[cat].pages = r.data.totalPages
          this.loading = false
        })
        .catch(e => {
          this.table.data[cat] = []
          this.table.pagination[cat].number = 1
          this.table.pagination[cat].pages = 1
          this.loading = false
        })
    },
    async downloadResource (item) {
      this.loading = true
      const response = await axios.get(`/api/v1/library/resource/${item.id}/download`, { responseType: 'blob' })
      if (response.status === 200) {
        const blob = new Blob([response.data])
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = item.name + '.pdf'
        link.click()
        URL.revokeObjectURL(link.href)
      }
      this.loading = false
    },
    async deleteResource () {
      this.loading = true
      await axios.delete(`/api/v1/library/resource/${this.selectForDelete.id}`)
      this.loading = false
      this.dialogDeleteConfirm = false
      await this.filter()
    },
    previewResource (item) {
      if (!this.loading) {
        window.open(`${window.location.origin}/#/library/preview/${item.id}?type=${item.type ? item.type : ""}`, '_blank')
      }
    },
    areYouSureForDelete (item) {
      this.selectForDelete = item
      this.dialogDeleteConfirm = true
      this.loading = true
    }
  }
}
